











import Vue from "@/vue-ext";
import { Component } from "vue-property-decorator";
import Layout from "../components/layouts/blank-layout.vue";

@Component({
  components: {
    Layout,
  },
})
export default class SignupResultPage extends Vue {
  back(): void {
    location.href = this.$baseWebsite;
  }
}
